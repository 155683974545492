import { EStores } from '../types/enums/taken-by.enum';

const USERS = [
  { store: 'Harrods Concession', name: 'Oksana Boichuk-Pitsyk' },
  { store: 'Harrods Concession', name: 'Valon Jakupi' },
  { store: 'Harrods Concession', name: 'Patrycja Zadrozna' },
  { store: 'Harrods Concession', name: 'Jason Wang' },
  { store: 'Belgravia Boutique', name: 'Bethany Lindsay' },
  { store: 'Belgravia Boutique', name: 'Miranda Chan' },
  { store: 'Belgravia Boutique', name: 'Chloe Picket' },
  { store: 'Belgravia Boutique', name: 'Ellie-Mai Pemberton' },
  { store: 'Belgravia Boutique', name: 'Patrycja Kubicka' },
  { store: 'Belgravia Boutique', name: 'Zhaneta Todeva' },
  { store: 'Belgravia Boutique', name: 'Rita Henry' },
  { store: 'Harrods Concession', name: 'Christopher Sidoli' },
  { store: 'Harrods Concession', name: 'Sophie Sadrettin' },
  { store: 'Harrods Concession', name: 'Maria Encheva' },
  { store: 'Harrods Concession', name: 'Blinnia Durnan' },
  { store: 'Harrods Concession', name: 'Alice Whittington' },
  { store: 'Harrods Concession', name: 'Galina Dimova' },
  { store: 'Harrods Concession', name: 'Inessa Vaivadaite' },
  { store: 'Harrods Concession', name: 'Millicent Sutton' },
  { store: 'Harrods Concession', name: 'Alexandra Smith ' },
  { store: 'Harrods Concession', name: 'Chloe Fernandez Saiz' },
  { store: 'Harrods Concession', name: 'Hannah Pattie' },
  { store: 'Harrods Concession', name: 'Jennifer Walson' },
  { store: 'Harrods Concession', name: 'Summer Darvell' },
];
export const getUsers = () => {
  USERS.sort((a, b) => a.name.localeCompare(b.name));
  return USERS;
};
export type TTakenBy = (typeof USERS)[number]['name'] | EStores.SHOPIFY;
