import * as React from 'react';
import { useReports } from './useReports';
import AllOrderFilters from '../../components/AllOrderFilters';
import { FloristLimitDataTable } from '../../components/LimitedDataTable';
import { EOrderMethod } from '../../types/enums/order-method.enum';
import { concatName } from '../../utils/name.util';
import { mapItemToString } from '../../utils/item-order.util';
import moment from 'moment';
import { formatAmount } from '../../utils/amount.util';

interface OrderReportLimitData {
  Id: string;
  'Customer Name': string;
  'Fulfillment Date'?: string;
  'Order Method': EOrderMethod;
  'Recipient Name': string;
  'Recipient Phone'?: string;
  'Delivery Post Code'?: string;
  'Delivery Street Address'?: string;
  Items?: string;
  Qty: string;
  'Total Price': string;
  'Card Message'?: string;
}

export function Reports() {
  const { filters, setFilters, currentUser, getAndDownloadReport, orders } = useReports();

  return (
    <div className="w-full flex flex-col">
      <AllOrderFilters currentUser={currentUser} filters={filters} setFilters={setFilters} key={'reports-filters'} />
      {orders && (
        <FloristLimitDataTable<OrderReportLimitData>
          data={orders.records.map((order) => ({
            Id: order._id,
            'Customer Name': concatName(order.customer?.firstName, order.customer?.lastName),
            'Recipient Name': concatName(order.recipientFirstName, order.recipientLastName),
            'Recipient Phone': `${order.recipientPhone?.slice(0, 6) ? `${order.recipientPhone?.slice(0, 6)}...` : 'N/A'}`,
            'Fulfillment Date': moment(order.fulfillmentDate).format('DD-MM-YYYY'),
            'Order Method': order.method,
            'Total Price': formatAmount(order.costSummary),
            'Delivery Post Code': order.deliveryAddress?.postCode,
            'Delivery Street Address': `${order.deliveryAddress?.streetAddress?.slice(0, 5) ? `${order.deliveryAddress?.streetAddress?.slice(0, 5)}...` : 'N/A'}`,
            'Card Message': `${order.card?.message?.slice(0, 7) ? `${order.card?.message?.slice(0, 7)}...` : 'N/A'}`,
            Items: `${order.itemDescription?.map(mapItemToString)?.join(', ').slice(0, 11)}...`,
            Qty: `${order.itemDescription?.reduce((p, { qty }) => (typeof qty === 'number' ? p + qty : p), 0) || 'N/A'}`,
          }))}
          key={'report-limit-data-table'}
          total={orders.total}
          action={getAndDownloadReport}
        />
      )}
    </div>
  );
}
