import React, { useEffect, useState } from 'react';
import { IconButton, Badge, Popover, Box, Button } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNotifications } from './useNotification';
import { NotificationList } from './NotificationList';
import { useLocation } from 'react-router-dom';

const NotificationDropDown: React.FC = () => {
  const location = useLocation();
  useEffect(() => {
    if (open) {
      handleClose();
    }
  }, [location]);

  const {
    notifications,
    totalUnread,
    totalNotifications,
    handleLoadMore,
    handleResetPage,
    handleNotificationsRead,
    handleNotificationClick,
    handleRemoveNotification,
  } = useNotifications();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  // When dropdown is clicked and is opened
  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    await handleNotificationsRead();
  };

  // When notification item is clicked
  const handleNotificationClickAndCloseDropdown = (id: string) => {
    handleClose();
    handleNotificationClick(id);
  };

  // When dropdown is closed
  const handleClose = () => {
    setAnchorEl(null);
    handleResetPage();
  };

  return (
    <>
      {/* Show unread notification count and icon for drop down */}
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={totalUnread} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {/* Dialog for all notifications and related components */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{ maxHeight: 420, minWidth: 350, overflow: 'auto', maxWidth: 500, py: 2, px: 3 }}
          className="!bg-opacity-5"
        >
          {notifications.length ? (
            <NotificationList
              notifications={notifications}
              onNotificationItemClick={handleNotificationClickAndCloseDropdown}
              onNotificationRemove={handleRemoveNotification}
            />
          ) : null}
          {/* Only show load more button when there are more notifications */}
          {totalNotifications && notifications.length < totalNotifications ? (
            <Button fullWidth onClick={handleLoadMore} sx={{ mt: 1 }} variant="outlined">
              Load more
            </Button>
          ) : (
            <p className="text-center">All Caught Up 👏</p>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default NotificationDropDown;
