import React from 'react';
import { Switch, FormControlLabel } from '@mui/material';

export interface IToggleSwitchProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    label?: string | React.ReactNode;
    labelPosition?: 'start' | 'end' | 'top' | 'bottom';
    switchColor?: 'primary' | 'secondary' | 'default';
}

export const FloristToggleSwitch: React.FC<IToggleSwitchProps> = ({
    checked,
    onChange,
    label,
    labelPosition = 'end',
    switchColor = 'primary',
}) => {
    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    onChange={(e) => onChange(e.target.checked)}
                    color={switchColor}
                    className="!ml-3"
                />
            }
            label={
                label && (
                    <span className="text-lg font-medium text-gray-800">
                        {label}
                    </span>
                )
            }
            labelPlacement={labelPosition}
            className="!flex !items-center"
        />
    );
};
