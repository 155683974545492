import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEditOrder } from './useEditOrder';
import { OrderDetails } from '../OrderDetails';

export function EditOrder() {
  const { id } = useParams<{ id: string }>();
  if (!id) {
    const navigate = useNavigate();
    navigate('/');
    return <></>;
  }
  const states = useEditOrder(id);
  return <OrderDetails action={'edit'} {...states} />;
}
