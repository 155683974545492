import { IAddress } from '../interfaces/address.interface';

const apiKey = 'M-1_aNdbck-9wnX9onFDjQ40680';
export const searchAddress = async (postCode: string): Promise<{ address: string; id: string }[]> => {
  try {
    const response = await (
      await fetch(`https://api.getAddress.io/autocomplete/${postCode.replaceAll(' ', '')}?api-key=${apiKey}&all=true`)
    ).json();

    return response.suggestions.map((s: { address: string; id: string }) => ({
      address: s.address as string,
      id: s.id as string,
    }));
  } catch (err) {
    /* empty */
    return [];
  }
};
export const getAddressDetails = async (id: string) => {
  const response = await (await fetch(`https://api.getAddress.io/get/${id}?api-key=${apiKey}`)).json();

  let formattedAddress = response.formatted_address
    ?.filter((a: string | null) => !!a && !['london', 'greater london'].includes(a.toLowerCase()))
    .join(', ');
  if (!formattedAddress)
    formattedAddress = [
      response.line_1,
      response.line_2,
      response.line_3,
      response.line_4,
      response.line_5,
      response.line_6,
    ]
      .filter((a) => !!a && !['london', 'greater london'].includes(a.toLowerCase()))
      .join(', ');
  return {
    postcode: response.postcode as string,
    state: response.district || response.country,
    streetAddress: formattedAddress,
    country: response.country,
    city: response.town_or_city,
  };
};

export const buildAddress = (addressObj?: IAddress) => {
  return [
    addressObj?.streetAddress,
    addressObj?.streetAddress2,
    addressObj?.state,
    addressObj?.city,
    addressObj?.postCode,
  ]
    .filter((a) => !!a)
    .join(', ');
};
