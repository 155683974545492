import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { IUploadedFile } from '../../interfaces/file.interface';
import FloristFileList from './FileList';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export type AttachedFile = Omit<IUploadedFile, 'url' | 'key'> & {
    file?: File; // The actual file object (for local files)
    url?: string;
    key?: string;
};

export interface IFileUploadButtonProps extends ButtonProps {
    label?: string;
    onFileChange: (files: AttachedFile[]) => void; // Callback to notify parent
    multiple?: boolean;
    files: AttachedFile[]; // Parent-provided initial files (local + uploaded)
}

export default function FloristFileUploadButton({
    label = 'Upload files',
    onFileChange,
    multiple = false,
    files = [],
    className,
    ...extra
}: IFileUploadButtonProps) {
    const [attachedFiles, setAttachedFiles] = React.useState<AttachedFile[]>([]);
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    // Sync with parent-provided files on first render
    React.useEffect(() => {
        setAttachedFiles(files);
    }, [files]);

    const handleFileChange = (selectedFilesList: FileList | null) => {
        const selectedFiles = Array.from(selectedFilesList || []);
        const newFiles: AttachedFile[] = selectedFiles.filter(
            (newFile) => !attachedFiles.some(({ originalname }) => {
                return originalname === newFile.name
            }
            )
        ).map(f => ({ file: f, originalname: f.name, size: f.size, url: undefined }))

        if (!newFiles.length) return;

        const updatedFiles = [...attachedFiles, ...newFiles];
        if (updatedFiles.length > 3) {
            alert('Maximum of 3 files can be attached')
            return;
        }


        setAttachedFiles(updatedFiles); // Update local state
        onFileChange(updatedFiles); // Notify parent

        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset input to allow reselection the same file
        }
    };

    const handleRemoveFile = (index: number) => {
        const updatedFiles = attachedFiles.filter((_, i) => i !== index);

        setAttachedFiles(updatedFiles); // Update local state
        onFileChange(updatedFiles); // Notify parent
    };


    return (
        <div className='flex'>
            {/* Upload Button */}
            <Button
                component="label"
                variant="contained"
                startIcon={<AttachFileIcon fontSize='medium' />}
                className={`${className} !rounded-md !capitalize !text-base !md:font-normal !p-3 !my-2 !tracking-wide !bg-secondary !text-white !font-thin`}
                {...extra}
            >
                {label}
                <VisuallyHiddenInput
                    type="file"
                    ref={fileInputRef} // Reference to reset input value
                    onChange={(event) => handleFileChange(event.target.files)}
                    max={3}
                    accept='.pdf,.jpg,.png,.svg,.jpeg,.csv,.txt'
                    multiple={multiple}

                />
            </Button>

            <FloristFileList
                files={attachedFiles}
                isEditable={true}
                handleRemoveFile={handleRemoveFile}
                key={'florist-upload'}
            />

        </div>
    );
}
