import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { AttachedFile } from './FileUploadButton';

interface Props {
    isEditable: boolean
    files: AttachedFile[]
    handleRemoveFile?: (index: number) => void
    className?: string
}
export default function FloristFileList({ isEditable = true, files, handleRemoveFile, className }: Props) {

    const getFileLink = (file: AttachedFile) => {
        // If already uploaded file url will be present
        if (file.url)
            return file.url
        // If not uploaded yet, a new file, the file object will be present
        else if (file.file)
            return URL.createObjectURL(file.file)
        else return;
    };
    return (
        files.length > 0 && (
            <List
                sx={{
                    marginTop: 0, // Align with the button
                    marginLeft: 2, // Add spacing from the button
                    display: 'flex', // Flexbox for horizontal alignment
                    alignItems: 'center',
                    padding: 0, // Remove default padding from List
                    gap: 1, // Add spacing between list items
                    overflowX: 'auto', // Allow horizontal scrolling if needed
                }}
                className={className}
            >
                {files.map((file, index) => (
                    <ListItem
                        key={index}
                        sx={{
                            display: 'flex', // Flex layout
                            flexDirection: 'row', // Horizontal alignment
                            alignItems: 'center',
                            justifyContent: 'space-between', // Space between text and delete button
                            padding: '8px 12px', // Add padding inside each list item
                            border: '1px solid #e0e0e0', // Add border for better separation
                            borderRadius: '8px', // Rounded corners for each file item
                            minWidth: '200px', // Ensure each file item has a consistent minimum width
                            maxWidth: '250px', // Limit the maximum width for file items
                        }}
                        secondaryAction={
                            isEditable ? (
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => handleRemoveFile?.(index)}
                                    sx={{
                                        color: '#ff0000', // Red color for delete button
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            ) : undefined
                        }
                    >
                        <ListItemText
                            primary={
                                <a
                                    href={getFileLink(file)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: 'none',
                                        fontSize: '0.9rem',
                                        color: '#000',
                                        whiteSpace: 'nowrap', // Prevent wrapping
                                        overflow: 'hidden', // Truncate overflow
                                        textOverflow: 'ellipsis', // Show ellipsis for long text
                                    }}
                                >
                                    {file.originalname}
                                </a>
                            }
                            secondary={`${(file.size / 1024).toFixed(2)} KB`}
                            sx={{
                                flex: 1, // Allow text to grow and take available space
                                marginRight: '8px', // Add spacing from the delete button
                            }}
                        />
                    </ListItem>
                ))}
            </List>

        ))
}