import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FloristTextField } from './TextField';

export interface DropDownWithQtyPickerOption {
  label: string;
  value: string;
  qty: number | 'Not Available';
}

interface FloristDropdownWithQtyPickerProps {
  options: DropDownWithQtyPickerOption[];
  selectId: string;
  selectLabel: string;
  onChange: (selectedOptions: DropDownWithQtyPickerOption[]) => void;
  selectedOptions: DropDownWithQtyPickerOption[];
  disabled?: boolean;
  className?: string;
  error?: string;
  multiple: boolean;
}

export const FloristDropdownWithQtyPicker: React.FC<FloristDropdownWithQtyPickerProps> = ({
  options,
  selectId,
  selectLabel,
  onChange,
  selectedOptions,
  disabled = false,
  className,
  error,
  multiple,
}) => {
  const [tempQuantities, setTempQuantities] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const initialQuantities = selectedOptions.reduce(
      (acc, option) => {
        acc[option.value] = option.qty.toString();
        return acc;
      },
      {} as { [key: string]: string }
    );
    setTempQuantities(initialQuantities);
  }, [selectedOptions]);

  const handleSelectionChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    const newSelectedOptions = (value as string[]).map((val) => {
      const existingOption = selectedOptions.find((o) => o.value === val);
      return existingOption || { label: val, value: val, qty: parseInt(tempQuantities[val], 10) || 1 };
    });
    onChange(newSelectedOptions);
  };

  const handleQtyChange = (optionValue: string, newQty: string) => {
    setTempQuantities((prev) => ({ ...prev, [optionValue]: newQty }));
  };

  const handleQtyBlur = (optionValue: string) => {
    const newQty = parseInt(tempQuantities[optionValue], 10);
    if (isNaN(newQty) || newQty <= 0) {
      // If newQty is not a number or <= 0, remove the option or revert to the original qty
      const updatedOptions = selectedOptions.filter((o) => o.value !== optionValue);
      onChange(updatedOptions);
      // Revert temp quantity to reflect the removed or original state
      setTempQuantities((prev) => {
        const updatedQuantities = { ...prev };
        delete updatedQuantities[optionValue];
        return updatedQuantities;
      });
    } else {
      // Update the option's quantity
      const updatedOptions = selectedOptions.map((o) => (o.value === optionValue ? { ...o, qty: newQty } : o));
      onChange(updatedOptions);
    }
  };

  return (
    <FormControl size="small" className={`w-full ${className}`} disabled={disabled}>
      <InputLabel id={`${selectId}-label`}>{selectLabel}</InputLabel>
      <Select
        labelId={`${selectId}-label`}
        id={selectId}
        multiple={multiple}
        value={selectedOptions.map((o) => o.value)}
        onChange={handleSelectionChange}
        renderValue={(selected) => selected.map((val) => options.find((o) => o.value === val)?.label || val).join(', ')}
        autoWidth
        label={selectLabel}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={selectedOptions.some((o) => o.value === option.value)} />
            <ListItemText primary={option.label} />
            {multiple && (
              <FloristTextField
                label="Qty"
                size="small"
                type="number"
                onClick={(e) => e.stopPropagation()} // Prevent Select from closing on TextField interaction
                value={tempQuantities[option.value] !== 'Not Available' ? tempQuantities[option.value] : ''}
                onChange={(e) => handleQtyChange(option.value, e)}
                onBlur={() => handleQtyBlur(option.value)}
                inputProps={{ min: 0 }}
                disabled={!selectedOptions.some((o) => o.value === option.value)}
              />
            )}
          </MenuItem>
        ))}
      </Select>
      {error && <p className="text-error text-sm">{`*${error}`}</p>}
    </FormControl>
  );
};
