export function formatParagraphedString(str?: string, charactersPerLine = 80): string {
  if (!str) return '';

  const paragraphs = str.split('\n');

  let resultingString = '';
  for (const paragraph of paragraphs) {
    const resultingLines: string[] = [];

    if (paragraph.trim() === '') {
      // Preserve empty lines
      resultingString += '\n';
      continue;
    }

    if (paragraph.length > charactersPerLine) {
      const words = paragraph.split(' ');
      let lineCharactersCount = 0;
      let resultingWordsForLine: string[] = [];

      for (const word of words) {
        if (lineCharactersCount + word.length + (resultingWordsForLine.length > 0 ? 1 : 0) <= charactersPerLine) {
          // Add the word to the current line
          resultingWordsForLine.push(word);
          lineCharactersCount += word.length + (resultingWordsForLine.length > 1 ? 1 : 0);
        } else {
          // Push the completed line and reset
          resultingLines.push(resultingWordsForLine.join(' '));
          resultingWordsForLine = [word];
          lineCharactersCount = word.length;
        }
      }

      // Add any remaining words as the last line
      if (resultingWordsForLine.length > 0) {
        resultingLines.push(resultingWordsForLine.join(' '));
      }
    } else {
      resultingLines.push(paragraph);
    }

    // Add the resulting lines for the current paragraph, separated by newlines
    resultingString += `${resultingLines.join('\n')}\n`;
  }

  // Trim trailing newlines and return the result
  return resultingString.trim();
}
