import {
  useDeleteDraftOrderMutation,
  useGetOrderQuery,
  useUpdateDraftOrderMutation,
  useUpdateOrderMutation,
} from '../../../store/order/order.api';
import moment from 'moment';
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EStores } from '../../../types/enums/taken-by.enum';
import { EOrderStatus } from '../../../types/enums/status.enum';
import { IDraftOrder } from '../../../interfaces/draft-order.interface';
import { IOrder } from '../../../interfaces/order.interface';

export const useViewOrder = (id: string) => {
  const { data } = useGetOrderQuery(id);
  const navigate = useNavigate();
  const [updateOrder] = useUpdateOrderMutation();
  const [updateDraftOrder] = useUpdateDraftOrderMutation();
  const [deleteDraftOrder] = useDeleteDraftOrderMutation();
  const [isDownloadingPdf, setIsDownloadingPdf] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  useEffect(() => {
    if (isDownloadingPdf) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const container = document.getElementById('order-details')!;
      htmlToImage.toPng(container, { quality: 0.9, skipAutoScale: true }).then(function (dataUrl) {
        const link = document.createElement('a');
        link.download = 'order.jpeg';
        const pdf = new jsPDF({});
        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const scaledWidth = pdfWidth - 4; // Reduce width as needed
        const pdfHeight = (imgProps.height * scaledWidth) / imgProps.width;
        pdf.addImage(dataUrl, 'JPEG', 2, 10, scaledWidth, pdfHeight, undefined, 'FAST');
        pdf.save(`${data?.shopifyData?.nsfcId || `#${data?._id}`}.pdf`);
      });
      setIsDownloadingPdf(false);
    }
  }, [isDownloadingPdf]);

  const isDraft = (orderData: IOrder | IDraftOrder | undefined) => {
    if (orderData) {
      return 'isDraft' in orderData && orderData.isDraft;
    }
    return false;
  };
  const onDeleteOrRestore = async (id: string) => {
    if (id && data) {
      if (isDraft(data)) {
        deleteDraftOrder({
          id,
        });
      } else {
        await updateOrder({
          updateDto: {
            id,
            orderStatus: data?.orderStatus === EOrderStatus.DELETED ? EOrderStatus.ACTIVE : EOrderStatus.DELETED,
          },
          files: data.files,
        });
      }
      navigate(-1);
    }
  };
  const onStatusChange = async (id: string, status: EOrderStatus) => {
    console.log(data, '======data');
    if (data && id) {
      if (isDraft(data)) {
        await updateDraftOrder({
          updateDto: { id, orderStatus: status },
          files: data.files,
        });
      } else {
        await updateOrder({
          updateDto: { id, orderStatus: status },
          files: data.files,
        });
      }
    }
  };
  return {
    orderDate: moment(data?.orderedAt),
    fulfilledAt: data?.fulfilledAt,
    orderTakenBy: data?.takenBy,
    customerFirstName: data?.customer?.firstName,
    customerLastName: data?.customer?.lastName,
    billingAddress: data?.customer?.billingAddress,
    customerPhone: data?.customer?.phone,
    customerEmail: data?.customer?.email,
    orderMethod: data?.method,
    deliveryAddress: data?.deliveryAddress,
    recipientContact: data?.recipientPhone,
    recipientFirstName: data?.recipientFirstName,
    recipientLastName: data?.recipientLastName,
    costSummary: data?.costSummary,
    cardMessage: data?.card?.message,
    deliveryCost: data?.deliveryCost,
    itemDescription: data?.itemDescription,
    orderStatus: data?.orderStatus,
    paymentStatus: data?.status,
    itemPrice: data?.itemPrice,
    content: data?.content,
    fulfillmentStatus: data?.fulfillmentStatus,
    fulfillmentDate: moment(data?.fulfillmentDate),
    fulfillmentTime: data?.fulfillmentTime,
    isDownloadingPdf,
    isShopifyOrder: data?.takenBy === EStores.SHOPIFY,
    files: data?.files || [],
    setIsDownloadingPdf,
    onDeleteOrRestore,
    onStatusChange,
    isDraft: isDraft(data),
    isConfirmationDialogOpen,
    setIsConfirmDialogOpen,
  };
};
