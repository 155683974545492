import moment, { Moment } from 'moment';
import { IAddress } from '../../../interfaces/address.interface';
import { ETimes } from '../../../types/enums/time.enum';
import { EOrderMethod } from '../../../types/enums/order-method.enum';
import { IOrder } from '../../../interfaces/order.interface';
import { EStores } from '../../../types/enums/taken-by.enum';
import { EStatus } from '../../../types/enums/payment-status.enum';
import { IOrderItem } from '../../../interfaces/item.interface';
import { EFulfillmentStatus } from '../../../types/enums/fulfillment-status.enum';
import { getDeliveryCost } from '../../../utils/delivery-cost.util';
import { validateDraftOrder, validateOrder } from '../helpers';
import { EOrderStatus } from '../../../types/enums/status.enum';
import { AttachedFile } from '../../../components/base/FileUploadButton';

export type TErrors = {
  [K in keyof NewOrderInitialStateType['fields']]?: string;
};
export interface SetComputedAction<
  K extends keyof NewOrderInitialStateType['computed'] = keyof NewOrderInitialStateType['computed'],
> {
  value: NewOrderInitialStateType['computed'][K];
  type: 'SET_COMPUTED';
  field: K;
}

export interface SetFieldAction<
  K extends keyof NewOrderInitialStateType['fields'] = keyof NewOrderInitialStateType['fields'],
> {
  value: NewOrderInitialStateType['fields'][K];
  type: 'SET_FIELD';
  field: K;
}

export interface SetFileAction {
  value: AttachedFile[];
  type: 'SET_FILE';
  field: 'files';
}

export interface SetBulkFieldsAction {
  value: Partial<Omit<NewOrderInitialStateType, 'errors'>>;
  type: 'SET_BULK_FIELD';
  field: 'fieldsANDcomputer';
}

interface SetErrorsAction {
  value: TErrors;
  type: 'SET_ERRORS';
  field: 'errors';
}

export type NewOrderInitialStateType = {
  errors: TErrors;
  fields: {
    orderDate: Moment;
    fulfillmentDate: Moment;
    itemDescription: IOrderItem[];

    fulfillmentStatus?: EFulfillmentStatus;
    fulfilledAt?: EStores;
    orderTakenBy?: IOrder['takenBy'];
    billingAddress?: IAddress;
    orderMethod?: EOrderMethod;
    fulfillmentTime?: ETimes;
    deliveryAddress?: IAddress;
    paymentStatus?: EStatus;

    customerFirstName?: string;
    customerLastName?: string;
    customerPhone?: string;
    customerEmail?: string;
    recipientFirstName?: string;
    recipientLastName?: string;
    recipientContact?: string;
    deliveryCost?: number;
    cardMessage?: string;
    itemPrice?: number;
    costSummary?: number;
    content?: string;
    orderStatus?: EOrderStatus;
  };
  files: AttachedFile[];
  computed: {
    isShopifyOrder: boolean;
    isOrderDetailsComplete: boolean;
    isConfirmationDialogOpen?: boolean;
  };
};

export type NewOrderActions =
  | SetFieldAction
  | SetFileAction
  | SetBulkFieldsAction
  | SetErrorsAction
  | SetComputedAction;

export const initialState: NewOrderInitialStateType = {
  errors: {},
  fields: {
    billingAddress: undefined,

    costSummary: undefined,
    customerFirstName: undefined,
    customerLastName: undefined,
    customerPhone: undefined,
    customerEmail: undefined,
    cardMessage: undefined,
    content: undefined,

    deliveryAddress: undefined,
    deliveryCost: undefined,

    fulfillmentDate: moment(),
    fulfillmentTime: undefined,
    fulfillmentStatus: undefined,
    fulfilledAt: undefined,

    itemDescription: [],
    itemPrice: undefined,

    orderMethod: undefined,
    orderDate: moment(),
    orderTakenBy: undefined,
    orderStatus: EOrderStatus.ACTIVE,

    paymentStatus: undefined,

    recipientFirstName: undefined,
    recipientLastName: undefined,
    recipientContact: undefined,
  },
  files: [],
  computed: {
    isOrderDetailsComplete: false,
    isShopifyOrder: false,
    isConfirmationDialogOpen: undefined,
  },
};

export const reducer = (state: NewOrderInitialStateType, action: NewOrderActions): NewOrderInitialStateType => {
  switch (action.type) {
    case 'SET_FIELD': {
      let updatedState = {
        ...state,
        fields: {
          ...state.fields,
          [action.field]: action.value,
        },
      };

      updatedState = computeErrors(state, updatedState, action);

      updatedState = onDeliveryAddressChange(state, updatedState, action);

      updatedState = updateCostSummaryWhenDeliveryCostChanged(state, updatedState, action);

      updatedState = onOrderMethodChange(state, updatedState, action);

      updatedState = onStoreChange(state, updatedState, action);

      return updatedState;
    }
    case 'SET_FILE': {
      return {
        ...state,
        files: action.value,
      };
    }
    case 'SET_BULK_FIELD': {
      let updatedState: NewOrderInitialStateType = {
        ...state,
        fields: {
          ...state.fields,
          ...(action.value.fields || {}),
        },
        computed: {
          ...state.computed,
          ...(action.value.computed || {}),
        },
      };

      updatedState = onDeliveryAddressChange(state, updatedState, action);

      updatedState = updateCostSummaryWhenDeliveryCostChanged(state, updatedState, action);

      updatedState = onOrderMethodChange(state, updatedState, action);

      updatedState = onStoreChange(state, updatedState, action);

      updatedState = computeErrors(state, updatedState, action);
      return updatedState;
    }
    case 'SET_COMPUTED':
      return {
        ...state,
        computed: {
          ...state.computed,
          [action.field]: action.value,
        },
      };
    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.value,
        computed: {
          ...state.computed,
          isOrderDetailsComplete: Object.values(action.value).length ? false : true,
        },
      };
    default:
      return state;
  }
};

const computeErrors = (
  _state: NewOrderInitialStateType,
  updatedState: NewOrderInitialStateType,
  action: NewOrderActions
): NewOrderInitialStateType => {
  if (action.type === 'SET_FIELD') {
    let errorsToSet: TErrors = {};
    const orderValidationErrors = validateOrder(updatedState) || {};
    const draftOrderValidationErrors = validateDraftOrder(updatedState) || {};
    if (Object.values(draftOrderValidationErrors).length) {
      errorsToSet = orderValidationErrors;
    }
    return {
      ...updatedState,
      errors: errorsToSet,
      computed: {
        ...updatedState.computed,
        isOrderDetailsComplete: Object.values(orderValidationErrors).length ? false : true,
      },
    };
  }
  return updatedState;
};

const updateCostSummaryWhenDeliveryCostChanged = (
  state: NewOrderInitialStateType,
  updatedState: NewOrderInitialStateType,
  action: NewOrderActions
): NewOrderInitialStateType => {
  if (
    action.type === 'SET_FIELD' &&
    (state.fields.deliveryCost !== updatedState.fields.deliveryCost ||
      action.field === 'deliveryCost' ||
      action.field === 'itemPrice' ||
      state.fields.itemPrice !== updatedState.fields.itemPrice)
  ) {
    return {
      ...updatedState,
      fields: {
        ...updatedState.fields,
        costSummary: (updatedState.fields.deliveryCost ?? 0) + (updatedState.fields.itemPrice ?? 0),
      },
    };
  }
  return updatedState;
};

const onDeliveryAddressChange = (
  state: NewOrderInitialStateType,
  updatedState: NewOrderInitialStateType,
  action: NewOrderActions
): NewOrderInitialStateType => {
  if (
    action.type === 'SET_FIELD' &&
    (action.field === 'deliveryAddress' || state.fields.deliveryAddress !== updatedState.fields.deliveryAddress)
  ) {
    const address = action.value as IAddress;
    if (address && address) {
      const { fulfilledAt } = updatedState.fields;
      const deliverCost = getDeliveryCost(address.postCode);
      if (typeof deliverCost !== 'number')
        return {
          ...updatedState,
          errors: {
            ...updatedState.errors,
            deliveryAddress: 'We do not deliver to this area.',
          },
        };
      else if (fulfilledAt && fulfilledAt !== EStores.HARRODS) {
        return {
          ...updatedState,
          fields: {
            ...updatedState.fields,
            deliveryCost: getDeliveryCost(address.postCode),
          },
        };
      }
    } else {
      return {
        ...state,
        errors: {
          ...updatedState.errors,
          deliveryAddress: undefined,
        },
      };
    }
  }
  return updatedState;
};

const onOrderMethodChange = (
  state: NewOrderInitialStateType,
  updatedState: NewOrderInitialStateType,
  action: NewOrderActions
): NewOrderInitialStateType => {
  if (
    action.type === 'SET_FIELD' &&
    (action.field === 'orderMethod' || state.fields.orderMethod !== updatedState.fields.orderMethod)
  ) {
    const { fulfilledAt, deliveryAddress } = updatedState.fields;
    const orderMethod = action.value;
    // If collection then there is no delivery fee
    if (orderMethod === EOrderMethod.COLLECTION) {
      return {
        ...updatedState,
        fields: {
          ...updatedState.fields,
          deliveryCost: 0,
        },
      };
    } else if (fulfilledAt !== EStores.HARRODS) {
      if (deliveryAddress?.postCode) {
        return {
          ...updatedState,
          fields: {
            ...updatedState.fields,
            deliveryCost: getDeliveryCost(deliveryAddress.postCode),
          },
        };
      } else {
        return {
          ...updatedState,
          fields: {
            ...updatedState.fields,
            deliveryCost: undefined,
          },
        };
      }
    }
  }
  return updatedState;
};

const onStoreChange = (
  state: NewOrderInitialStateType,
  updatedState: NewOrderInitialStateType,
  action: NewOrderActions
): NewOrderInitialStateType => {
  if (
    action.type === 'SET_FIELD' &&
    (action.field === 'fulfilledAt' || state.fields.fulfilledAt !== updatedState.fields.fulfilledAt)
  ) {
    const { orderMethod, deliveryAddress } = updatedState.fields;
    const newStore = action.value;
    if (orderMethod === EOrderMethod.COLLECTION) {
      return {
        ...updatedState,
        fields: {
          ...updatedState.fields,
          deliveryCost: 0,
        },
      };
    } else if (newStore === EStores.HARRODS && !updatedState.computed.isShopifyOrder) {
      return {
        ...updatedState,
        fields: {
          ...updatedState.fields,
          deliveryCost: undefined,
        },
      };
    } else if (deliveryAddress?.postCode) {
      return {
        ...updatedState,
        fields: {
          ...updatedState.fields,
          deliveryCost: getDeliveryCost(deliveryAddress.postCode),
        },
      };
    }
  }
  return updatedState;
};
