import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from '../components/base/Layout';
import ProtectedRoute from '../components/ProtectedRoute';
import { Login } from '../pages/login/Login';
import { OrdersList } from '../pages/order/list-orders/ListOrders';
import { EditOrder } from '../pages/order/edit-order/EditOrder';
import { NewOrder } from '../pages/order/new-order/NewOrder';
import { ViewOrder } from '../pages/order/view-order/ViewOrder';
import { ForgotPassword } from '../pages/password/ForgotPassword';
import { ResetPassword } from '../pages/password/ResetPassword';
import { VerifyAccount } from '../pages/password/VerifyAccount';
import { Settings } from '../pages/settings/Settings';
import { TeamManagement } from '../pages/team/team';
import { Reports } from '../pages/reports/Reports';
import ArchivedOrders from '../pages/archived-orders/ArchivedOrders';
export function FloristRouter() {
  const [isAuth, setAuth] = useState<boolean>(false);

  return (
    <Router>
      <Layout isAuth={isAuth} setAuth={setAuth}>
        <Routes>
          <Route path="/all-orders" element={<ProtectedRoute isAuth={isAuth} setAuth={setAuth} />}>
            <Route path="/all-orders" Component={OrdersList} />
          </Route>
          <Route path="/archived-orders" element={<ProtectedRoute isAuth={isAuth} setAuth={setAuth} />}>
            <Route path="/archived-orders" Component={ArchivedOrders} />
          </Route>
          <Route path="/new-order" element={<ProtectedRoute isAuth={isAuth} setAuth={setAuth} />}>
            {' '}
            <Route path="/new-order" Component={NewOrder} />
          </Route>
          <Route path="/order/edit/:id" element={<ProtectedRoute isAuth={isAuth} setAuth={setAuth} />}>
            {' '}
            <Route path="/order/edit/:id" Component={EditOrder} />
          </Route>
          <Route path="/reports" element={<ProtectedRoute isAuth={isAuth} setAuth={setAuth} />}>
            {' '}
            <Route path="/reports" Component={Reports} />
          </Route>
          <Route path="/team" element={<ProtectedRoute isAuth={isAuth} setAuth={setAuth} />}>
            {' '}
            <Route path="/team" Component={TeamManagement} />
          </Route>
          <Route path="/order/view/:id" element={<ProtectedRoute isAuth={isAuth} setAuth={setAuth} />}>
            {' '}
            <Route path="/order/view/:id" Component={ViewOrder} />
          </Route>
          <Route path="/settings" element={<ProtectedRoute isAuth={isAuth} setAuth={setAuth} />}>
            {' '}
            <Route path="/settings" Component={Settings} />
          </Route>
          <Route path="/" Component={Login} />
          <Route path="/forgot-password" Component={ForgotPassword} />
          <Route path="/reset-password/:token" Component={ResetPassword} />
          <Route path="/setup-account/:token" Component={VerifyAccount} />
          <Route path="*" Component={Login} />
        </Routes>
      </Layout>
    </Router>
  );
}
