import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useEffect, useReducer } from 'react';
import {
  useConvertDraftOrderToOrderMutation,
  useGetOrderQuery,
  useUpdateDraftOrderMutation,
  useUpdateOrderMutation,
} from '../../../store/order/order.api';
import { EOrderMethod } from '../../../types/enums/order-method.enum';
import { EStores } from '../../../types/enums/taken-by.enum';
import { useGetUserQuery } from '../../../store/user/user.api';
import { ETimes } from '../../../types/enums/time.enum';
import * as reducer from './reducer';
import { IOrder } from '../../../interfaces/order.interface';
import { AttachedFile } from '../../../components/base/FileUploadButton';
import { IDraftOrder } from '../../../interfaces/draft-order.interface';

export const useEditOrder = (id: string) => {
  const { data } = useGetOrderQuery(id);
  const [updateOrder] = useUpdateOrderMutation();
  const [updateDraftOrder] = useUpdateDraftOrderMutation();
  const [convertDraftToOrder] = useConvertDraftOrderToOrderMutation();
  const { data: currentUser } = useGetUserQuery({});
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer.reducer, reducer.initialState);

  const setField = (field: reducer.SetFieldAction['field'], value: reducer.SetFieldAction['value']) => {
    dispatch({ type: 'SET_FIELD', field, value });
  };

  const setFiles = (files: AttachedFile[]) => {
    dispatch({ type: 'SET_FILE', field: 'files', value: files });
  };

  const setComputed = (field: reducer.SetComputedAction['field'], value: reducer.SetComputedAction['value']) => {
    dispatch({ type: 'SET_COMPUTED', field, value });
  };
  useEffect(() => {
    if (data) {
      const newState: Partial<Omit<reducer.EditOrderInitialStateType, 'errors'>> = {
        fields: {
          fulfilledAt: data?.fulfilledAt,
          orderTakenBy: data?.takenBy,
          orderDate: data.orderedAt ? moment(data.orderedAt) : moment(),
          customerFirstName: data.customer?.firstName,
          customerLastName: data.customer?.lastName,
          customerEmail: data.customer?.email,
          customerPhone: data.customer?.phone,
          billingAddress: data.customer?.billingAddress,
          cardMessage: data.card?.message,
          content: data.content,
          costSummary: data.costSummary,
          deliveryAddress: data.deliveryAddress,
          itemDescription: data.itemDescription || [],
          orderMethod: data.method,
          paymentStatus: data.status,
          recipientContact: data.recipientPhone,
          fulfillmentDate: data.fulfillmentDate ? moment(data?.fulfillmentDate) : moment(),
          fulfillmentTime: data.fulfillmentTime,
          recipientFirstName: data.recipientFirstName,
          recipientLastName: data.recipientLastName,
          itemPrice: data.itemPrice,
          deliveryCost: data.deliveryCost,
          fulfillmentStatus: data.fulfillmentStatus,
        },
        computed: {
          isShopifyOrder: data.takenBy === EStores.SHOPIFY,
          isOrderDetailsComplete: !isDraft(data), // for now i added this
          isConfirmationDialogOpen: false,
          isDraft: isDraft(data),
        },
        files: data.files,
      };
      dispatch({ type: 'SET_BULK_FIELD', field: 'fieldsANDcomputer', value: newState });
    }
  }, [data]);

  const isDraft = (orderData: IOrder | IDraftOrder) => {
    if (orderData) {
      return 'isDraft' in orderData && orderData.isDraft;
    }
    return false;
  };

  const onSubmitAction = () => {
    const { computed, errors } = state;
    if (Object.values(errors).length) {
      return 'SHOW_VALIDATION_ERRORS';
    }
    if (computed.isDraft) {
      // The order was draft when we started editing
      if (computed.isOrderDetailsComplete) {
        return 'CONVERT_TO_ORDER';
      } else {
        return 'EDIT_DRAFT';
      }
    } else {
      // The order was complete when we started editing
      if (computed.isOrderDetailsComplete) {
        return 'EDIT_ORDER';
      } else {
        return 'UNKNOWN_ISSUE';
      }
    }
  };

  const onSubmit = async () => {
    const fields = state.fields;
    const dto = {
      costSummary: fields.costSummary,
      customer: {
        billingAddress: fields.billingAddress,
        email: fields.customerEmail,
        firstName: fields.customerFirstName,
        lastName: fields.customerLastName,
        phone: fields.customerPhone,
      },
      fulfillmentDate: fields.fulfillmentDate?.toDate(),
      fulfillmentTime: fields.fulfillmentTime || ETimes.NOT_SPECIFIED,
      deliveryAddress: fields.orderMethod === EOrderMethod.DELIVERY ? fields.deliveryAddress : undefined,
      method: fields.orderMethod,
      orderedAt: fields.orderDate?.toDate(),
      recipientFirstName: fields.recipientFirstName,
      recipientLastName: fields.recipientLastName,
      recipientPhone: fields.recipientContact,
      status: fields.paymentStatus,
      fulfilledAt: fields.fulfilledAt,
      takenBy: fields.orderTakenBy,
      card: fields.cardMessage ? { message: fields.cardMessage } : undefined,
      content: fields.content,
      deliveryCost: fields.deliveryCost,
      itemDescription: fields.itemDescription,
      itemPrice: fields.itemPrice,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
      id: data?._id!,
      fulfillmentStatus: fields.fulfillmentStatus,
    };

    try {
      switch (onSubmitAction()) {
        case 'EDIT_ORDER': {
          await updateOrder({ updateDto: dto, files: state.files }).unwrap();
          break;
        }
        case 'CONVERT_TO_ORDER': {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await convertDraftToOrder({ convertDto: dto as any, files: state.files }).unwrap();
          break;
        }
        case 'EDIT_DRAFT': {
          await updateDraftOrder({ updateDto: { ...dto, isDraft: true }, files: state.files }).unwrap();
          break;
        }
        case 'SHOW_VALIDATION_ERRORS':
        case 'UNKNOWN_ISSUE':
        default:
          return;
      }

      navigate(-1);
    } catch (err) {
      return;
    }
  };

  return {
    state: state,
    onSubmit,
    currentUser: currentUser?.user,
    setField,
    setComputed,
    setFiles,
  };
};
