/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import { FloristButton } from '../../components/base/Button';
import { FloristTable } from '../../components/base/Table';
import { useGetUserQuery, useGetUsersListQuery } from '../../store/user/user.api';
import { ERole } from '../../types/enums/roles.enum';
import MemberModal from './MemberModal';
import { concatName } from '../../utils/name.util';

interface ITableItem {
  Name: string;
  Role: ERole;
  Email: string;
  Id: string;
}
export function TeamManagement() {
  const { data: users } = useGetUsersListQuery({});
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [action, setAction] = React.useState<'edit' | 'view' | 'create'>();
  const [userId, setUserId] = React.useState<string>();
  const { data: currentUser } = useGetUserQuery({});
  const userList = users?.filter((u) => u._id !== currentUser?.user?._id);
  return (
    <div>
      {action && (
        <MemberModal
          isOpen={isModalOpen}
          setIsOpen={() => {
            setIsModalOpen(false);
            setUserId(undefined);
            setAction(undefined);
          }}
          user={userList?.find((u) => u._id === userId)}
          action={action}
        />
      )}
      <div className="w-full p-2 mb-2 grid grid-cols-5 gap-0 mt-6 grid-rows-1">
        <div className="col-span-4 m-0">
          <h2 className="tracking-widest m-0 text-2xl !font-thin">{'Team Members'}</h2>
          <p className="tracking-widest m-0 text-medium leading-3 text-gray !font-thin">{'Manage Your Users'}</p>
        </div>
        <FloristButton
          onClick={() => {
            setIsModalOpen(true);
            setAction('create');
          }}
          className="col-span-1 justify-self-end !mr-6"
          label="Add User"
        />
      </div>
      <FloristTable<ITableItem>
        data={
          userList?.map((u) => ({
            Name: concatName(u.firstName, u.lastName),
            Email: u.email,
            Role: u.role,
            Id: u._id ?? '',
            ACTIONS: [
              {
                onClick: () => {
                  setIsModalOpen(true);
                  setUserId(u._id);
                  setAction('edit');
                },
                label: 'EDIT',
              },
              {
                onClick: () => {
                  setIsModalOpen(true);
                  setUserId(u._id);
                  setAction('view');
                },
                label: 'VIEW',
              },
            ],
          })) ?? []
        }
      />
    </div>
  );
}
