import * as React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { FloristButton } from './Button';

interface IExtras {
  isDone?: boolean;
  isCancelled?: boolean;
}
export interface IFloristTableProps<T> {
  data: Array<T & IExtras>;
  onPageChange?: (newPageNumber: number) => void;
  onLimitChange?: (newLimit: number) => void;
  limit?: number;
  page?: number;
  total?: number;
}

export function FloristTable<T>({ data, onPageChange, onLimitChange, limit, page, total }: IFloristTableProps<T>) {
  if (!data.length) return <p className="text-center">No Records Found</p>;

  return (
    <Paper className="m-1" sx={{ overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: '70vh' }}>
        <Table stickyHeader aria-label="sticky table"
          sx={{ tableLayout: 'auto' }} // Let the browser adjust column widths automatically

        >
          <TableHead>
            <TableRow>
              {Object.keys(data[0] ?? {}).map(
                (header, index) =>
                  !['isDone', 'isCancelled',].includes(header) && (
                    <TableCell
                      key={index}
                      className={`!bg-secondary !text-white !text-base !px-2 ${header === 'ACTIONS' ? '!text-center' : ''}`}
                      style={{ textTransform: 'capitalize' }}
                      size="small"
                    >
                      {header !== 'NO_KEY' ? header : ''}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map(({ isDone, isCancelled, ...item }, rowIndex) => (
              <TableRow className={isDone ? 'bg-radiant-green' : ''} hover role="checkbox" tabIndex={-1} key={rowIndex}>
                {Object.entries(item as unknown as Record<string, string | []>).map(([key, value], colIndex) => (
                  <TableCell
                    size="medium"
                    className={`capitalize !px-2 !py-1 !text-lg ${isCancelled && key !== 'ACTIONS' ? 'line-through' : ''}`}
                    key={colIndex}
                    sx={['CUSTOMER NAME', 'RECIPIENT NAME'].includes(key) ? {
                      wordBreak: 'break-word', // Break long words
                      whiteSpace: 'normal', // Wrap text
                      maxWidth: '200px', // Optional: set a max-width for specific columns
                    } : {}}
                  >
                    {key === 'ACTIONS' ? (
                      <div className="flex self-center !justify-self-center" key={`${colIndex}-action`}>
                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        {(value as unknown as [any]).map((v, i) => (
                          <FloristButton
                            key={`${i}-act-butt`}
                            className="!text-sm !p-1 !w-16 !m-1"
                            onClick={() => v?.onClick?.()}
                            label={v.label}
                          />
                        ))}
                      </div>
                    ) : (
                      (value as string)
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {limit && onPageChange && onLimitChange && total && page && (
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={limit}
          page={page - 1}
          onPageChange={(_e, n) => {
            onPageChange?.(n + 1);
          }}
          size="small"
          onRowsPerPageChange={(e) => onLimitChange?.(+e.target.value)}
        />
      )}
    </Paper>
  );
}
