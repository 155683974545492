import * as React from 'react';
import { FloristButton } from '../../components/base/Button';
import { FloristSmallHeading } from '../../components/base/Small-Heading';
import { useLogin } from './useLogin';
import { FloristLoader } from '../../components/base/Loader';
import LogoSVG from '../../assets/logo/logo.svg';
import { FloristTextField } from '../../components/base/TextField';

export function Login() {
  const { email, onSubmit, password, setEmail, setPassword, isLoading, onForgotPassword } = useLogin();
  return (
    <div className="min-h-screen flex items-center justify-center ">
      <div className="w-1/3 h-1/2 bg-white p-8 rounded shadow-md !justify-items-center">
        <div className="mb-10 text-center">
          <img src={LogoSVG} className="w-36 m-auto mt-8 mb-5" alt="Logo" />
          <FloristSmallHeading additionalClassNames={['text-xl', '!font-mono']} className="font-medium" label="Welcome Back!" />
        </div>
        {isLoading ? (
          <FloristLoader />
        ) : (
          <div className='flex flex-col'>
            <FloristTextField
              label="Email Address"
              id="email"
              value={email}
              placeholder="example@email.com"
              type={'text'}
              onChange={(e) => setEmail(e)}
              className='!w-60 !mb-2 font-semibold shadow-sm'
              autoFocus
            // containerClassNames='!self-center flex'
            />
            <FloristTextField
              label="Password"
              id="password"
              value={password}
              placeholder="IAmaStrongPassword"
              type={'password'}
              onChange={(e) => setPassword(e)}
            // className='!w-60 !border-none shadow-sm'

            />
            <div className="!my-10 !flex !flex-col items-center ">
              <FloristButton id="password" label="LOGIN" type="button" onClick={onSubmit} />
              <a className="text-sm hover:cursor-pointer hover:underline" onClick={onForgotPassword}>
                Forgot Password ?
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
