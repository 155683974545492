import * as React from 'react';
import { FloristButton } from '../../components/base/Button';
import { FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material';
import { FloristDatePicker } from '../../components/base/DatePicker';
import { FloristDropdown } from '../../components/base/Dropdown';
import { FloristPhoneField } from '../../components/base/PhoneField';
import { EOrderMethod } from '../../types/enums/order-method.enum';
import { EStatus } from '../../types/enums/payment-status.enum';
import { FloristAddressField } from '../../components/base/AddressField';
import { EStores } from '../../types/enums/taken-by.enum';
import { FloristTextField } from '../../components/base/TextField';
import { EFulfillmentStatus } from '../../types/enums/fulfillment-status.enum';
import { getUsers } from '../../constants/user.constant';
import { IOrder } from '../../interfaces/order.interface';
import { ETimes } from '../../types/enums/time.enum';
import { ITEMS_DESCRIPTION } from '../../constants/item-description.constant';
import { IUser } from '../../interfaces/user.interface';
import { ERole } from '../../types/enums/roles.enum';
import { EOrderStatus } from '../../types/enums/status.enum';
import { ConfirmationDialog } from '../../components/base/ConfirmDialog';
import { FloristDropdownWithQtyPicker } from '../../components/base/DropdownWithQtyPicker';
import { NewOrderInitialStateType } from './new-order/reducer';
import { EditOrderInitialStateType } from './edit-order/reducer';
import FloristFileUploadButton, { AttachedFile } from '../../components/base/FileUploadButton';

interface Props {
  action: 'create' | 'edit';
  onSubmit: () => void;
  currentUser?: IUser;
  state: NewOrderInitialStateType;
  setField: <K extends keyof NewOrderInitialStateType['fields'] = keyof NewOrderInitialStateType['fields']>(
    field: K,
    value: NewOrderInitialStateType['fields'][K]
  ) => void;
  setFiles: (
    files: AttachedFile[]
  ) => void;
  setComputed: <K extends keyof NewOrderInitialStateType['computed'] = keyof NewOrderInitialStateType['computed']>(
    field: K,
    value: NewOrderInitialStateType['computed'][K]
  ) => void;
}

export function OrderDetails(props: Props) {
  const {
    state: { fields, files, computed, errors },
    setField,
    setComputed,
    setFiles,
    currentUser,
    onSubmit,
    action,
  } = props;
  const isShopifyOrder = typeof computed.isShopifyOrder === 'boolean' && computed.isShopifyOrder; // fields.action === 'view';
  const isCancelled = fields.orderStatus === EOrderStatus.CANCELLED;
  const isDisabled = isShopifyOrder || isCancelled;
  return (
    <div className="flex flex-col">
      <div className="w-full p-4 border-collapse border border-secondary !rounded-xl mb-2">
        <div className="grid grid-cols-3 gap-2 grid-rows-1 w-full">
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Order Date</h5>
            <FloristDatePicker
              disabled={isDisabled}
              label="Order Date"
              onChange={(nDate) => {
                nDate && !isShopifyOrder && setField('orderDate', nDate);
              }}
              value={fields.orderDate}
              error={errors.orderDate}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Fulfilled At</h5>
            <FloristDropdown
              onChange={(newVal) => {
                setField('fulfilledAt', newVal as EStores);
              }}
              options={
                isShopifyOrder
                  ? Object.values(EStores).map((o) => ({ label: o.toUpperCase(), value: o }))
                  : Object.values(EStores)
                    .map((o) => ({ label: o.toUpperCase(), value: o }))
                    .filter((o) => o.value !== EStores.SHOPIFY)
              }
              selectId="edit-order-fulfilled-at"
              selectLabel="Fulfilled At"
              value={fields.fulfilledAt}
              disabled={isCancelled || currentUser?.role !== ERole.ADMIN}
              key={'order-taken-at-store-edit-key'}
              error={errors.fulfilledAt}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Order Taken By</h5>
            <FloristDropdown
              disabled={isDisabled}
              onChange={(newVal) => {
                !isShopifyOrder && setField('orderTakenBy', newVal as IOrder['takenBy']);
              }}
              value={fields.orderTakenBy}
              options={[
                ...Object.values(getUsers()).map((o) => ({ label: o.name.toUpperCase(), value: o.name })),
                ...(isShopifyOrder ? [{ label: 'Shopify', value: 'shopify-store' }] : []),
              ]}
              selectId="edit-order-taken-by"
              selectLabel="Order Taken By"
              key={'order-taken-by-edit-key'}
              error={errors.orderTakenBy}
            />
          </div>
        </div>
      </div>
      <div className="w-full p-4 border-collapse border border-secondary !rounded-xl mb-2">
        <h5 className="tracking-wide text-secondary-heading mb-4">Customer Details</h5>
        <div className="grid grid-cols-2 grid-rows-2 w-full gap-2">
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Name</h5>
            <div>
              <div className="grid grid-cols-2 gap-2 grid-rows-1">
                <div className="col-span-1">
                  <FloristTextField
                    disabled={isDisabled}
                    label="First Name"
                    onChange={!isShopifyOrder ? (val) => setField('customerFirstName', val) : undefined}
                    value={fields.customerFirstName ?? ''}
                    size="small"
                    fullWidth
                    errorMessage={errors.customerFirstName}
                  />
                </div>
                <div className="col-span-1">
                  <FloristTextField
                    label="Last Name"
                    disabled={isDisabled}
                    fullWidth
                    onChange={!isShopifyOrder ? (val) => setField('customerLastName', val) : undefined}
                    value={fields.customerLastName}
                    size="small"
                    errorMessage={errors.customerLastName}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Billing Address</h5>
            <FloristAddressField
              label="Billing Address"
              onReset={() => {
                !isShopifyOrder && setField('billingAddress', undefined);
              }}
              selectId="billing-address-select"
              selectLabel="Select Address"
              address={fields.billingAddress}
              disabled={isDisabled}
              key={'billing-address-search'}
              onChange={(address) => !isShopifyOrder && setField('billingAddress', address)}
              error={errors.billingAddress}
              showNotesField={false}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Tel Number</h5>
            <FloristPhoneField
              onChange={(e) => {
                !isShopifyOrder && setField('customerPhone', e);
              }}
              disabled={isDisabled}
              value={fields.customerPhone ?? ''}
              error={errors.customerPhone}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Email Address</h5>
            <FloristTextField
              fullWidth
              label="Email Address"
              onChange={!isShopifyOrder ? (v) => setField('customerEmail', v) : undefined}
              disabled={isDisabled}
              value={fields.customerEmail ?? ''}
              size="small"
              type={'email'}
              errorMessage={errors.customerEmail}
            />
          </div>
        </div>
      </div>
      <div className="w-full p-4 border-collapse border border-secondary !rounded-xl mb-2">
        <h5 className="tracking-wide text-secondary-heading mb-4">Delivery Details</h5>
        <div className="grid grid-cols-3 grid-rows-2 gap-2 w-full">
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Method</h5>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="justify-evenly"
              onChange={(e) => {
                !isShopifyOrder && setField('orderMethod', e.target.value as EOrderMethod);
              }}
            >
              {Object.values(EOrderMethod).map((o, i) => (
                <FormControlLabel
                  slotProps={{ typography: { fontSize: 'small' } }}
                  key={`${i}${o}`}
                  value={o}
                  control={<Radio disabled={isDisabled} checked={fields.orderMethod === o} size="small" />}
                  label={o.toUpperCase()}
                />
              ))}
            </RadioGroup>
            {errors.orderMethod && <p className="text-error text-sm">{`*${errors.orderMethod}`}</p>}
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Recipient Name</h5>
            <div>
              <div className="grid grid-cols-2 gap-2 grid-rows-1">
                <div className="col-span-1">
                  <FloristTextField
                    disabled={isDisabled}
                    label="First Name"
                    onChange={!isShopifyOrder ? (v) => setField('recipientFirstName', v) : undefined}
                    value={fields.recipientFirstName ?? ''}
                    size="small"
                    fullWidth
                    errorMessage={errors.recipientFirstName}
                  />
                </div>
                <div className="col-span-1">
                  <FloristTextField
                    label="Last Name"
                    disabled={isDisabled}
                    fullWidth
                    onChange={!isShopifyOrder ? (v) => setField('recipientLastName', v) : undefined}
                    value={fields.recipientLastName}
                    size="small"
                    errorMessage={errors.recipientLastName}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">{'Recipient Contact Number'}</h5>
            <FloristPhoneField
              onChange={(e) => {
                !isShopifyOrder && setField('recipientContact', e);
              }}
              value={fields.recipientContact ?? ''}
              disabled={isDisabled}
              error={errors.recipientContact}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Delivery Address</h5>
            <FloristAddressField
              label="Delivery Address"
              onReset={() => {
                !isShopifyOrder && setField('deliveryAddress', undefined);
              }}
              selectId="delivery-address-select"
              selectLabel="Select Address"
              address={fields.deliveryAddress}
              disabled={isShopifyOrder || fields.orderMethod !== EOrderMethod.DELIVERY}
              key={'delivery-address-search'}
              onChange={(address) => !isShopifyOrder && setField('deliveryAddress', address)}
              error={errors.deliveryAddress}
              showNotesField={true}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">
              {fields.orderMethod === EOrderMethod.DELIVERY ? 'Delivery Date And Time' : 'Collection Date And Time'}
            </h5>
            <FloristDatePicker
              // includeTime
              className="col-span-1"
              disabled={isDisabled}
              label={fields.orderMethod === EOrderMethod.DELIVERY ? 'Deliver Date' : 'Collection Date'}
              value={fields.fulfillmentDate}
              onChange={(e) => {
                e && !isShopifyOrder && setField('fulfillmentDate', e);
              }}
              error={errors.fulfillmentDate}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">
              {' '}
              {fields.orderMethod === EOrderMethod.DELIVERY ? 'Delivery Time' : 'Collection Time'}
            </h5>
            <RadioGroup
              row
              name={fields.orderMethod === EOrderMethod.DELIVERY ? 'delivery-time' : 'collection-time'}
              className="justify-evenly"
              onChange={(e) => {
                !isShopifyOrder && setField('fulfillmentTime', e.target.value as ETimes);
              }}
            >
              {Object.values(ETimes).map((o, i) => (
                <FormControlLabel
                  slotProps={{ typography: { fontSize: 'small' } }}
                  key={`${i}${o}`}
                  value={o}
                  control={<Radio disabled={isDisabled} checked={fields.fulfillmentTime === o} size="small" />}
                  label={o.toUpperCase()}
                />
              ))}
            </RadioGroup>
            {errors.fulfillmentTime && <p className="text-error text-sm">{`*${errors.fulfillmentTime}`}</p>}
          </div>
        </div>
      </div>
      <div className="w-full p-4 border-collapse border mb-4 border-secondary !rounded-xl ">
        <h5 className="tracking-wide text-secondary-heading mb-4">Order Details</h5>
        <div className="grid grid-cols-3 grid-rows-4 gap-2 w-full">
          <div className="col-span-1 row-span-2 bg-gray-200">
            <h5 className="text-xs mb-3">Card Message</h5>
            {computed.isShopifyOrder ? (
              <p className="text-sm text-light">{fields.cardMessage}</p>
            ) : (
              <TextareaAutosize
                value={fields.cardMessage ?? ''}
                onChange={(e) => {
                  !isShopifyOrder && setField('cardMessage', e.target.value);
                }}
                className="border text-lg border-secondary w-full !overflow-auto !h-32 p-2 "
                disabled={isDisabled}
              />
            )}
          </div>
          <div className="col-span-1 row-span-2 bg-gray-200">
            <h5 className="text-xs mb-3">Content</h5>
            {computed.isShopifyOrder ? (
              <p className="text-sm text-light">{fields.content}</p>
            ) : (
              <TextareaAutosize
                value={fields.content}
                onChange={(e) => {
                  !isShopifyOrder && setField('content', e.target.value);
                }}
                className="border text-lg border-secondary w-full !overflow-auto !h-32 p-2"
                disabled={isDisabled}
              />
            )}
          </div>
          <div className="col-span-1 row-span-2 bg-gray-200">
            <h5 className="text-xs mb-3">Item Description</h5>
            <FloristDropdownWithQtyPicker
              options={ITEMS_DESCRIPTION.map((v) => ({ label: v, value: v, qty: 0 }))}
              selectId="edit-order-item-description"
              selectLabel="Item Description"
              onChange={(val) => {
                !isShopifyOrder &&
                  setField(
                    'itemDescription',
                    val.map((v) => ({ label: v.label, qty: v.qty }))
                  );
              }}
              selectedOptions={fields.itemDescription?.map(({ label, qty }) => ({ label, value: label, qty })) || []}
              multiple={true}
              disabled={isDisabled}
              error={errors.itemDescription}
            />
          </div>
          <div className="col-span-1 bg-gray-200 ">
            <h5 className="text-xs mb-3">Delivery Cost</h5>
            <FloristTextField
              fullWidth
              label="Delivery Cost"
              type={'number'}
              value={typeof fields.deliveryCost === 'number' ? fields.deliveryCost : ''}
              onChange={(val) => {
                fields.fulfilledAt === EStores.HARRODS &&
                  setField('deliveryCost', val && typeof +val === 'number' ? Number(val) : undefined);
              }}
              size="small"
              prefix="£"
              disabled={
                isDisabled ||
                fields.orderMethod === EOrderMethod.COLLECTION ||
                !fields.fulfilledAt ||
                fields.fulfilledAt !== EStores.HARRODS
              }
              errorMessage={errors.deliveryCost}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Item Price</h5>
            <FloristTextField
              fullWidth
              label="Price"
              type={'number'}
              prefix="£"
              value={typeof fields.itemPrice === 'number' ? fields.itemPrice : ''}
              onChange={(e) => {
                !isShopifyOrder && setField('itemPrice', e && typeof +e === 'number' ? Number(e) : undefined);
              }}
              size="small"
              disabled={isDisabled}
              errorMessage={errors.itemPrice}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Total Cost</h5>
            <FloristTextField
              type={'number'}
              value={fields.costSummary ?? ''}
              fullWidth
              label="Total Cost"
              size="small"
              prefix="£"
              disabled={true}
              errorMessage={errors.costSummary}
            />
          </div>
          <div className="col-span-1 bg-gray-200">
            <h5 className="text-xs mb-3">Payment Status</h5>
            <FloristDropdown
              onChange={(e) => {
                !isShopifyOrder && setField('paymentStatus', e as EStatus);
              }}
              value={fields.paymentStatus ?? ''}
              options={Object.values(EStatus).map((v) => ({ label: v, value: v }))}
              selectId="edit-order-status"
              selectLabel="Payment Status"
              disabled={isDisabled}
              error={errors.paymentStatus}
            />
          </div>
          {action !== 'create' && (
            <div className="col-span-1 bg-gray-200">
              <h5 className="text-xs mb-3">Fulfillment Status</h5>
              <FloristDropdown
                onChange={(e) => {
                  setField('fulfillmentStatus', e as EFulfillmentStatus);
                }}
                value={fields.fulfillmentStatus}
                options={Object.values(EFulfillmentStatus).map((v) => ({ label: v, value: v }))}
                selectId="edit-fulfillment-status"
                selectLabel="Fulfillment Status"
                disabled={isCancelled}
                error={errors.fulfillmentStatus}
              />
            </div>
          )}
        </div>
      </div>
      {
        <div className="mx-4 flex justify-between mb-4">
          <FloristFileUploadButton
            onFileChange={(files) => files ? setFiles(files) : null}
            label='Attach Files'
            multiple
            files={files}
          />
          <FloristButton
            disabled={isCancelled || !!Object.values(errors).length}
            onClick={() => (action === 'edit' ? setComputed('isConfirmationDialogOpen', true) : onSubmit())}
            label={(() => {
              if (action === 'edit') {
                if ((computed as EditOrderInitialStateType['computed']).isDraft) {
                  return `${computed.isOrderDetailsComplete ? 'Convert To Order' : ' Edit Draft'}`
                } else {
                  return 'Edit Order'
                }
              }
              // Return Create Order or Create Draft
              return `Create ${computed.isOrderDetailsComplete ? 'Order' : 'Draft'}`
            })()}
            className="col-span-1 row-span-2 justify-self-end !mr-6 capitalize"
          />
        </div>
      }
      {computed.isConfirmationDialogOpen && (
        <ConfirmationDialog
          isOpen={computed.isConfirmationDialogOpen}
          title="Confirm Edit"
          content="Are you sure you want to edit this order?"
          onConfirm={() => onSubmit()}
          onCancel={() => setComputed('isConfirmationDialogOpen', false)}
        />
      )}
    </div>
  );
}
