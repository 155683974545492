import { useEffect, useState } from 'react';
import { useGetUserQuery } from '../../store/user/user.api';
import { IFilters } from '../../interfaces/order-filters.interface';
import Cookies from 'js-cookie';
import { useGetOrdersListQuery } from '../../store/order/order.api';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { ListOrdersResponseDto } from '../../store/order/dto/list.dto';

export const useReports = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const allParameters = (qs.parse(location.search) as unknown as IFilters & { page: string; limit: string }) || {};

  const { data: currentUser } = useGetUserQuery({});
  const [filters, setFilters] = useState<IFilters>({ ...allParameters, 'fulfilled-at': currentUser?.user?.store });

  useEffect(() => {
    const newQSParams = qs.stringify(filters);

    if (newQSParams !== qs.stringify(allParameters)) {
      navigate(`?${newQSParams}`);
    }
  }, [filters]);

  const getAndDownloadReport = async () => {
    const queryParams = new URLSearchParams(filters as Record<string, string>).toString();

    const response = await fetch(`${process.env.REACT_APP_SERVER_URL || '/api'}/reporting/orders?${queryParams}`, {
      method: 'GET',
      headers: new Headers({
        'x-api-key': 'DhKY3vDqDp8!ym$7jUD@K4PXu7PPkd',
        Authorization: `Bearer ${Cookies.get('access_token')}`,
      }),
    });

    if (!response.ok) {
      return;
    }

    // Assuming the response is a Blob if it's a file
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = 'orders-report.csv'; // Specify the filename
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(downloadUrl);
    a.remove();
  };
  const { data: orders } = useGetOrdersListQuery({ ...filters, limit: 3 });

  return {
    filters,
    currentUser: currentUser?.user,
    orders: orders as ListOrdersResponseDto,
    setFilters,
    getAndDownloadReport,
  };
};
