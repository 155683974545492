import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetDraftOrdersListQuery, useGetOrdersListQuery } from '../../../store/order/order.api';
import { useGetUserQuery } from '../../../store/user/user.api';
import { IFilters } from '../../../interfaces/order-filters.interface';
import { EOrderStatus } from '../../../types/enums/status.enum';
import qs from 'query-string';
import { checkIfObjectsEqual } from '../../../utils/object.utils';
export const useOrders = (params?: { orderStatus?: EOrderStatus; }) => {
  const { orderStatus } = params || {};

  const location = useLocation();
  const navigate = useNavigate();

  const allParameters = (qs.parse(location.search) as unknown as (Omit<IFilters, 'show-drafts'> & { page: string; limit: string, 'show-drafts': string })) || {};
  const { limit: qsLimit, page: qsPage, 'show-drafts': showDrafts, ...queryParams } = allParameters;

  const boolShowDraft = showDrafts === 'true'

  const [limit, setLimit] = useState<number>(Number(qsLimit) || 20);
  const [page, setPage] = useState<number>(Number(qsPage) || 1);

  const [filters, setFilters] = useState<IFilters>({
    ...queryParams,
    'order-status': orderStatus,
    'show-drafts': boolShowDraft,
  });

  useEffect(() => {
    let resetPage = false;

    if (!checkIfObjectsEqual(filters, { ...queryParams, 'show-drafts': boolShowDraft }) || limit !== Number(qsLimit)) {
      setPage(1);
      resetPage = true;
    }

    const newQSParams = qs.stringify({ ...filters, page: resetPage ? 1 : page, limit });

    if (newQSParams !== qs.stringify(allParameters)) {
      navigate(`?${newQSParams}`);
    }
  }, [filters, page, limit]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let listFn: any;
  if (filters['show-drafts']) {
    listFn = useGetDraftOrdersListQuery
  } else {
    listFn = useGetOrdersListQuery
  }
  const { data } = listFn({ limit, page, ...filters });
  const { data: currentUser } = useGetUserQuery({});

  return {
    limit: limit,
    total: data?.total,
    page: page,
    records: data?.records,
    setPage,
    setLimit,
    navigate,
    filters,
    setFilters,
    currentUser: currentUser?.user,
  };
};
