import * as React from 'react';
import LogoSVG from '../../assets/logo/logo.svg';
import { FloristButton } from '../../components/base/Button';
import { FloristLoader } from '../../components/base/Loader';
import { FloristSmallHeading } from '../../components/base/Small-Heading';
import { useForgotPassword } from './useForgotPassword';
import { FloristTextField } from '../../components/base/TextField';

// export interface IForgotPasswordProps {
// }

export function ForgotPassword() {
  const { email, setEmail, onSubmit, isLoading, isSuccess } = useForgotPassword();
  return (
    <div className="min-h-screen flex items-center justify-center ">
      <div className="w-1/2 h-1/2 bg-white p-8 rounded shadow-md !justify-items-center">
        <div className="mb-10 text-center">
          <img src={LogoSVG} className="w-36 m-auto mt-8 mb-5" alt="Logo" />
          {!isSuccess && (
            <FloristSmallHeading
              additionalClassNames={['text-base']}
              label="We need to send you email containing link to reset your password."
            />
          )}
          <FloristSmallHeading
            additionalClassNames={['text-base', 'font-semibold']}
            label={
              isSuccess
                ? `An email has been sent to ${email}, please follow the steps mentioned in the email. You can now close this window.`
                : 'Please provide the email linked to your account.'
            }
          />
        </div>
        {isLoading ? (
          <FloristLoader />
        ) : (
          <>
            <FloristTextField
              label="Email Address"
              id="email"
              value={email}
              placeholder="Email Address"
              type={'text'}
              onChange={(e) => setEmail(e)}
              className='!w-60 !mb-2 font-semibold shadow-sm'
              disabled={!!isSuccess}
            />
            <div className="mb-6">
              {!isSuccess && <FloristButton id="forgot-password" label="SUBMIT" type="button" onClick={onSubmit} />}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
