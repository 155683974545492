import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FloristButton } from './base/Button';

export interface IFloristTableProps<T> {
  data: Array<T>;
  total?: number;
  action?: () => void;
}

export function FloristLimitDataTable<T>({ data, total, action }: IFloristTableProps<T>) {
  if (!data.length) return <p className="text-center">No Records Found</p>;

  // Calculate the number of columns
  const columnCount = Object.keys(data[0] as object).length;

  return (
    <Paper className="m-1" sx={{ overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {Object.keys(data[0] ?? {}).map((header, index) => (
                <TableCell
                  key={index}
                  className="!bg-secondary !text-white !text-base !px-5"
                  style={{ textTransform: 'capitalize' }}
                  size="small"
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((item, rowIndex) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                {Object.values(item as unknown as Record<string, string | []>).map((value, colIndex) => (
                  <TableCell size="small" className={'capitalize !px-5 !py-1'} key={colIndex}>
                    {value as string}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {/* Dots row */}
            <TableRow>
              <TableCell
                style={{ textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}
                colSpan={columnCount}
              >
                {/* Using Typography for consistent styling with Material-UI */}
                <div>
                  {'.'}
                  <br />
                  {'.'}
                  <br />
                  {'.'}
                  <br />
                  {'.'}
                  <br />
                  {'.'}
                  <br />
                  {'.'}
                  <br />
                  <FloristButton
                    label={`Download CSV Report for ${total} records found`}
                    className="!w-2/5"
                    onClick={action}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
