import { useNavigate } from 'react-router-dom';
import { EOrderMethod } from '../../../types/enums/order-method.enum';
import { useCreateDraftOrderMutation, useCreateOrderMutation } from '../../../store/order/order.api';
import { ETimes } from '../../../types/enums/time.enum';
import { useGetUserQuery } from '../../../store/user/user.api';
import { EOrderStatus } from '../../../types/enums/status.enum';
import * as newOrderReducer from './reducer';
import { useEffect, useReducer } from 'react';
import { AttachedFile } from '../../../components/base/FileUploadButton';

export const useNewOrder = () => {
  const navigate = useNavigate();
  const { data: currentUser } = useGetUserQuery({});
  const [state, dispatch] = useReducer(newOrderReducer.reducer, newOrderReducer.initialState);

  useEffect(() => {
    if (currentUser?.user?.store && !state.fields.fulfilledAt)
      dispatch({ type: 'SET_FIELD', field: 'fulfilledAt', value: currentUser.user.store });
  }, [currentUser]);
  const [createOrder] = useCreateOrderMutation();
  const [createDraftOrder] = useCreateDraftOrderMutation();

  const onSubmit = async () => {
    if (!Object.values(state.errors).length) {
      const fields = state.fields;
      const computed = state.computed;
      const data = {
        costSummary: fields.costSummary,
        customer: {
          billingAddress: fields.billingAddress,
          email: fields.customerEmail,
          firstName: fields.customerFirstName,
          lastName: fields.customerLastName,
          phone: fields.customerPhone,
        },
        fulfillmentDate: fields.fulfillmentDate?.toDate(),
        fulfillmentTime: fields.fulfillmentTime || ETimes.NOT_SPECIFIED,
        deliveryAddress: fields.orderMethod === EOrderMethod.DELIVERY ? fields.deliveryAddress : undefined,
        method: fields.orderMethod,
        orderedAt: fields.orderDate?.toDate(),
        recipientFirstName: fields.recipientFirstName,
        recipientLastName: fields.recipientLastName,
        recipientPhone: fields.recipientContact,
        status: fields.paymentStatus,
        fulfilledAt: fields.fulfilledAt,
        takenBy: fields.orderTakenBy,
        card: fields.cardMessage ? { message: fields.cardMessage } : undefined,
        content: fields.content,
        deliveryCost: fields.deliveryCost,
        itemDescription: fields.itemDescription,
        itemPrice: fields.itemPrice,
        orderStatus: EOrderStatus.ACTIVE,
      };

      const files = state.files;
      if (computed.isOrderDetailsComplete) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await createOrder({ createDto: data as any, files: files })
          .catch((e) => console.log(e))
          .then(() => navigate(-1));
      } else {
        await createDraftOrder({ createDto: { ...data, isDraft: true }, files })
          .catch((e) => console.log(e))
          .then(() => navigate(-1));
      }
      // if(p)
      // navigate(-1);
    }
  };

  const setFiles = (files: AttachedFile[]) => {
    dispatch({ type: 'SET_FILE', field: 'files', value: files });
  };
  const setField = (field: newOrderReducer.SetFieldAction['field'], value: newOrderReducer.SetFieldAction['value']) => {
    dispatch({ type: 'SET_FIELD', field, value });
  };
  const setComputed = (
    field: newOrderReducer.SetComputedAction['field'],
    value: newOrderReducer.SetComputedAction['value']
  ) => {
    dispatch({ type: 'SET_COMPUTED', field, value });
  };

  return {
    state: state,
    onSubmit,
    currentUser: currentUser?.user,
    setField,
    setComputed,
    setFiles,
  };
};
