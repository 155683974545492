import moment, { Moment } from 'moment';
import * as React from 'react';
import { DateRange, DateRangePicker, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';

export interface IFloristDateRangePickerProps {
  onChange: (value: DateRange<Moment> | null) => void;
  label?: string;
  value?: DateRange<Moment>;
  disabled?: boolean;
  includeTime?: boolean;
  error?: string;
}
const shortcutsItems: PickersShortcutsItem<DateRange<Moment>>[] = [
  {
    label: 'Today',
    getValue: () => {
      const todayStart = moment().startOf('day');
      const todayEnd = moment().endOf('day');
      return [todayStart, todayEnd];
    },
  },
  {
    label: 'This Week',
    getValue: () => {
      const thisWeekStart = moment().startOf('week');
      const thisWeekEnd = moment().endOf('week');
      return [thisWeekStart, thisWeekEnd];
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const previousWeekStart = moment().subtract(7, 'days').startOf('week');
      const previousWeekEnd = moment().subtract(7, 'days').endOf('week');
      return [previousWeekStart, previousWeekEnd];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const last7DaysStart = moment().subtract(7, 'days').startOf('day');
      const last7DaysEnd = moment().endOf('day');
      return [last7DaysStart, last7DaysEnd];
    },
  },
  {
    label: 'Current Month',
    getValue: () => {
      const currentMonthStart = moment().startOf('month');
      const currentMonthEnd = moment().endOf('month');
      return [currentMonthStart, currentMonthEnd];
    },
  },
  {
    label: 'Next Month',
    getValue: () => {
      const nextMonthStart = moment().add(1, 'month').startOf('month');
      const nextMonthEnd = moment().add(1, 'month').endOf('month');
      return [nextMonthStart, nextMonthEnd];
    },
  },
  { label: 'Reset', getValue: () => [null, null] },
];

export function FloristDateRangePicker(props: IFloristDateRangePickerProps) {
  return (
    <div className="col-span-1">
      <DemoContainer
        sx={{
          width: '100%',
          paddingTop: 0,
          overflow: 'visible',
        }}
        components={['SingleInputDateRangeField']}
      >
        <DateRangePicker<Moment>
          label={props.label}
          format="DD/MM/YYYY"
          slotProps={{
            shortcuts: {
              items: shortcutsItems,
            },
            actionBar: { actions: [] },
          }}
          sx={{
            '& .MuiInputBase-input': {
              padding: '8px',
              size: 'small',
              overflow: 'scroll',
            },
            minWidth: '0 !important',
            '& .MuiFormLabel-root': {
              top: '-6px',
            },
          }}
          // calendars={2}
          slots={{ field: SingleInputDateRangeField }}
          onChange={(e) => {
            props.onChange?.([e?.[0]?.startOf('day') || null, e?.[1]?.endOf('day') || null]);
          }}
          value={props.value}
        />
      </DemoContainer>

      {props.error && <p className="text-error text-sm">{`*${props.error}`}</p>}
    </div>
  );
}
