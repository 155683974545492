import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import {
  ListDraftOrdersResponseDto,
  ListOrdersIncludingDraftsResponseDto,
  ListOrdersResponseDto,
  TListDraftOrdersQueryDto,
  TListOrdersQueryDto,
} from './dto/list.dto';
import {
  UpdateDraftOrderRequestDto,
  UpdateDraftOrderResponseDto,
  UpdateOrderRequestDto,
  UpdateOrderResponseDto,
} from './dto/update.dto';
import { AttachedFile } from '../../components/base/FileUploadButton';
import {
  ConvertDraftOrderRequestDto,
  ConvertDraftOrderResponseDto,
  CreateDraftOrderRequestDto,
  CreateDraftOrderResponseDto,
  CreateOrderRequestDto,
  CreateOrderResponseDto,
} from './dto/create.dto';
import { IDraftOrder } from '../../interfaces/draft-order.interface';
import { IOrder } from '../../interfaces/order.interface';

export const orderApi = createApi({
  reducerPath: 'order',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL || '/api',
    headers: {
      'x-api-key': 'DhKY3vDqDp8!ym$7jUD@K4PXu7PPkd',
    },
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${Cookies.get('access_token')}`);
      return headers;
    },
  }),
  tagTypes: ['order', 'orders'],
  endpoints: (builder) => ({
    getOrdersList: builder.query<ListOrdersResponseDto | ListOrdersIncludingDraftsResponseDto, TListOrdersQueryDto>({
      query: (filters) => ({
        url: 'order/list',
        params: filters,
      }),
      providesTags: ['orders'],
    }),
    createOrder: builder.mutation<CreateOrderResponseDto, { createDto: CreateOrderRequestDto; files?: AttachedFile[] }>(
      {
        query: ({ createDto, files }) => {
          // Create FormData object
          const formData = new FormData();

          // Append each file to the FormData object
          if (files) {
            files
              .filter((f) => !f.url)
              .forEach((file) => {
                if (file.file) formData.append('files', file.file); // Use unique keys for each file
              });
          }

          // Append the JSON payload as a string
          formData.append('createDto', JSON.stringify(createDto));

          return {
            url: 'order',
            method: 'POST',
            body: formData, // Send FormData as the body
          };
        },
        invalidatesTags: [{ type: 'orders' }],
      }
    ),
    createDraftOrder: builder.mutation<
      CreateDraftOrderResponseDto,
      { createDto: CreateDraftOrderRequestDto; files?: AttachedFile[] }
    >({
      query: ({ createDto, files }) => {
        // Create FormData object
        const formData = new FormData();

        // Append each file to the FormData object
        if (files) {
          files
            .filter((f) => !f.url)
            .forEach((file) => {
              if (file.file) formData.append('files', file.file); // Use unique keys for each file
            });
        }

        // Append the JSON payload as a string
        formData.append('createDto', JSON.stringify(createDto));

        return {
          url: 'order/draft',
          method: 'POST',
          body: formData, // Send FormData as the body
        };
      },
      invalidatesTags: [{ type: 'orders' }],
    }),
    updateOrder: builder.mutation<
      UpdateOrderResponseDto,
      { updateDto: UpdateOrderRequestDto & { id: string }; files?: AttachedFile[] }
    >({
      query: ({ updateDto, files }) => {
        // Create FormData object
        const formData = new FormData();

        const [uploadedFiles, newFiles] = files?.reduce<[AttachedFile[], AttachedFile[]]>(
          (acc, file) => {
            file.url ? acc[0].push(file) : acc[1].push(file); // Group files based on `url`
            return acc; // Return the accumulator for the next iteration
          },
          [[], []] // Initial value: two empty arrays
        ) || [[], []]; // Fallback in case `files` is undefined

        // Append each file to the FormData object
        if (newFiles.length) {
          newFiles.forEach((file) => {
            if (file.file) formData.append('files', file.file); // Use unique keys for each file
          });
        }

        // Append the JSON payload as a string
        formData.append('updateDto', JSON.stringify({ ...updateDto, files: uploadedFiles }));

        return {
          url: `order/${updateDto.id}`,
          method: 'PATCH',
          body: formData, // Send FormData as the body
        };
      },
      invalidatesTags: (result) => [{ type: 'order', id: result?._id }, { type: 'orders' }],
    }),
    getOrder: builder.query<IOrder | IDraftOrder, string>({
      query: (id) => ({
        url: `order/${id}`,
      }),
      providesTags: (result) => [{ type: 'order', id: result?._id }],
    }),
    /**
     * Draft Orders
     */
    getDraftOrdersList: builder.query<ListDraftOrdersResponseDto, TListDraftOrdersQueryDto>({
      query: (filters) => ({
        url: 'order/draft/list',
        params: filters,
      }),
      providesTags: ['orders'],
    }),
    updateDraftOrder: builder.mutation<
      UpdateDraftOrderResponseDto,
      { updateDto: UpdateDraftOrderRequestDto & { id: string }; files?: AttachedFile[] }
    >({
      query: ({ updateDto, files }) => {
        // Create FormData object
        const formData = new FormData();

        const [uploadedFiles, newFiles] = files?.reduce<[AttachedFile[], AttachedFile[]]>(
          (acc, file) => {
            file.url ? acc[0].push(file) : acc[1].push(file); // Group files based on `url`
            return acc; // Return the accumulator for the next iteration
          },
          [[], []] // Initial value: two empty arrays
        ) || [[], []]; // Fallback in case `files` is undefined

        // Append each file to the FormData object
        if (newFiles.length) {
          newFiles
            .filter((f) => !f.url)
            .forEach((file) => {
              if (file.file) formData.append('files', file.file); // Use unique keys for each file
            });
        }

        // Append the JSON payload as a string
        formData.append('updateDto', JSON.stringify({ ...updateDto, files: uploadedFiles }));

        return {
          url: `order/draft/${updateDto.id}`,
          method: 'PATCH',
          body: formData, // Send FormData as the body
        };
      },
      invalidatesTags: (result) => [{ type: 'order', id: result?._id }, { type: 'orders' }],
    }),
    deleteDraftOrder: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `order/draft/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'orders' }],
    }),
    convertDraftOrderToOrder: builder.mutation<
      ConvertDraftOrderResponseDto,
      { convertDto: ConvertDraftOrderRequestDto; files?: AttachedFile[] }
    >({
      query: ({ convertDto, files }) => {
        // Create FormData object
        const formData = new FormData();

        const [uploadedFiles, newFiles] = files?.reduce<[AttachedFile[], AttachedFile[]]>(
          (acc, file) => {
            file.url ? acc[0].push(file) : acc[1].push(file); // Group files based on `url`
            return acc; // Return the accumulator for the next iteration
          },
          [[], []] // Initial value: two empty arrays
        ) || [[], []]; // Fallback in case `files` is undefined

        // Append each file to the FormData object
        if (newFiles.length) {
          newFiles.forEach((file) => {
            if (file.file) formData.append('files', file.file); // Use unique keys for each file
          });
        }

        // Append the JSON payload as a string
        formData.append('convertDto', JSON.stringify({ ...convertDto, files: uploadedFiles }));

        return {
          url: 'order/draft/convert',
          method: 'POST',
          body: formData, // Send FormData as the body
        };
      },
      invalidatesTags: [{ type: 'orders' }],
    }),
  }),
});
export const {
  useGetOrdersListQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useGetOrderQuery,
  useCreateDraftOrderMutation,
  useGetDraftOrdersListQuery,
  useUpdateDraftOrderMutation,
  useDeleteDraftOrderMutation,
  useConvertDraftOrderToOrderMutation,
} = orderApi;
