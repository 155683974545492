import * as React from 'react';

export interface IInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string;
  id: string;
  labelClassNames?: string;
  containerClassNames?: string
}

export function FloristInput({ id, label, containerClassNames = '', labelClassNames = '', ...extra }: IInputProps) {
  return (
    <div className={`mb-4 ${containerClassNames}`}>
      <label
        htmlFor={`${id.toLowerCase()}`}
        className={`block text-gray-700 text-base mb-2 font-extraLight ${labelClassNames}`}
      >
        {label}
      </label>
      <input
        id={id}
        type={extra.type}
        {...extra}
        className={`w-full border border-border border-solid px-2 ${extra.className}`}
      />
    </div>
  );
}
