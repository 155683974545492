import React from 'react';
import { MuiTelInput } from 'mui-tel-input';

interface IFloristPhoneFieldProps {
  value?: string;
  onChange: (newVal: string) => void;
  disabled?: boolean;
  error?: string;
}
export const FloristPhoneField = (props: IFloristPhoneFieldProps) => {
  return (
    <>
      <MuiTelInput
        disabled={props.disabled}
        size="small"
        defaultCountry="GB"
        fullWidth
        value={props.value}
        onChange={props.onChange}
      />
      {props.error && <p className="text-error text-sm">{`*${props.error}`}</p>}
    </>
  );
};
