import { DatePicker, DateTimePicker } from '@mui/x-date-pickers-pro';
import { Moment } from 'moment';
import * as React from 'react';

export interface IFloristDatePickerProps {
  onChange: (value: Moment | null) => void;
  label?: string;
  value?: Moment;
  disabled?: boolean;
  includeTime?: boolean;
  error?: string;
  className?: string;
}

export function FloristDatePicker(props: IFloristDatePickerProps) {
  return (
    <>
      {props.includeTime ? (
        <DateTimePicker<Moment>
          label={props.label}
          disabled={props.disabled}
          className={`${props.className} w-full`}
          slotProps={{ textField: { size: 'small', value: props.value } }}
          onChange={props.onChange}
          value={props.value}
          format="DD/MM/YYYY"
        />
      ) : (
        <DatePicker<Moment>
          label={props.label}
          disabled={props.disabled}
          className={`${props.className} w-full`}
          slotProps={{ textField: { size: 'small', value: props.value } }}
          onChange={props.onChange}
          value={props.value}
          format="DD/MM/YYYY"
        />
      )}
      {props.error && <p className="text-error text-sm">{`*${props.error}`}</p>}
    </>
  );
}
