import _ from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function checkIfObjectsEqual(receivedObjA: any, receivedObjB: any, depth = 1) {
  const objA = _.omitBy(receivedObjA, _.isUndefined);
  const objB = _.omitBy(receivedObjB, _.isUndefined);
  if (typeof objA !== 'object' || typeof objB !== 'object') {
    throw new Error('Non object types');
  }
  if (depth !== 1) {
    throw new Error('Please implement depth functionality in util');
  }
  if (Object.keys(objA).length !== Object.keys(objB).length) return false;

  let isSame = true;

  for (const i of Object.keys(objA)) {
    if (objB[i] !== objA[i]) {
      isSame = false;
      break;
    }
  }
  return isSame;
}
