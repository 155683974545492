import { useEffect, useRef, useState } from 'react';
import {
  useMarkNotificationsAsReadMutation,
  useRemoveNotificationMutation,
} from '../../store/notification/notification.api';
import { INotification } from '../../interfaces/notification.interface';
import { useNavigate } from 'react-router-dom';
import { useSSE } from '../../hooks/useSSENotifications';
import { ListNotificationsResponseDto, TListNotificationsQueryDto } from '../../store/notification/dto/list.dto';
import Cookies from 'js-cookie';

export const useNotifications = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [totalUnread, setTotalUnread] = useState<number>(0);
  const [totalNotifications, setTotalNotifications] = useState<number>(0);
  const initialRender = useRef(true);

  const [markAsRead] = useMarkNotificationsAsReadMutation();
  const [removeNotification] = useRemoveNotificationMutation();

  const { notification } = useSSE();

  useEffect(() => {
    if (initialRender.current) {
      // skip fetching notifications on initial render. Because otherwise two api calls are made once on render and once when page is set
      initialRender.current = false;
    } else {
      handleRefreshNotifications({ limit: 5, page });
    }
  }, [page]);

  useEffect(() => {
    if (notification) {
      /**
       * Fetch notification but fetch 5 * page because if user was on page  2,3 then
       *  he would be seeing more records than initial limit and we want to fetch all those
       */
      handleRefreshNotifications({ limit: 5 * page, page: 1 });
    }
  }, [notification]);

  const handleResetPage = () => setPage(1);
  const handleNotificationClick = (id: string) => navigate(`/order/view/${id}`);
  const handleNotificationsRead = async (newOverrideNotifications?: INotification[]) => {
    // notificationToMarkAsRead to be provided only when notifications need to be marked as read but not
    const unreadNotificationIds = (newOverrideNotifications || notifications)
      .filter((n) => !n.viewed)
      .map((n) => n._id || '');

    if (unreadNotificationIds.length > 0) {
      await markAsRead({ notificationIds: unreadNotificationIds });
    }
    setNotifications((newOverrideNotifications || notifications).map((n) => ({ ...n, viewed: true })));
    setTotalUnread((prev) => prev - unreadNotificationIds.length);
  };
  const handleRemoveNotification = async (id: string) => {
    await removeNotification(id);
    handleRefreshNotifications({ limit: 5 * page, page: 1 });
  };
  const handleLoadMore = async () => setPage((prevPage) => prevPage + 1);
  const handleRefreshNotifications = async ({ limit, page }: { limit: number; page: number }) => {
    const data = await refreshNotifications({ limit, page });

    if (data) {
      if (page > 1) {
        const oldAndNewNotifications: INotification[] = [...notifications, ...data.records];
        await handleNotificationsRead(oldAndNewNotifications);
      } else {
        // At first load, or when a new notification is received
        setNotifications(data.records);
        setTotalNotifications(data.total);
        setTotalUnread(data.totalUnread);
      }
    }
  };

  return {
    notifications,
    totalUnread,
    totalNotifications,
    handleNotificationsRead,
    handleNotificationClick,
    handleRemoveNotification,
    handleLoadMore,
    handleResetPage,
  };
};
export const refreshNotifications = async ({
  limit,
  page,
}: TListNotificationsQueryDto): Promise<ListNotificationsResponseDto> => {
  try {
    // Assuming your API endpoint for fetching notifications looks something like this
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL || '/api'}/notifications?limit=${limit}&page=${page}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'DhKY3vDqDp8!ym$7jUD@K4PXu7PPkd',
          Authorization: `Bearer ${Cookies.get('access_token')}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Notification refresh error');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return {
      limit: 0,
      page: 0,
      records: [],
      total: 0,
      totalUnread: 0,
    }; // Or however you want to handle errors
  }
};
