import * as React from 'react';

export interface IButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  additionalClassNames?: string[];
  label: string;
}

export function FloristButton({ className, label, onClick, disabled, ...extra }: IButtonProps) {
  return (
    <button
      {...extra}
      disabled={disabled}
      className={`
        w-36 md:w-40 rounded-md text-base md:font-normal p-1 my-2 tracking-wide 
         ${disabled ? 'bg-gray-400 text-gray-200 cursor-not-allowed font-extralight' : 'bg-secondary text-white font-thin'
        } ${className}`}
      onClick={disabled ? undefined : onClick} // Prevent click when disabled
    >
      {label}
    </button>
  );
}
