import { EOrderMethod } from '../../types/enums/order-method.enum';
import { EStores } from '../../types/enums/taken-by.enum';
import { NewOrderInitialStateType, TErrors as TNewOrderErrors } from './new-order/reducer';
import { EditOrderInitialStateType, TErrors as TEditOrderErrors } from './edit-order/reducer';

export const validateOrder = (state: NewOrderInitialStateType | EditOrderInitialStateType) => {
  let errors: TEditOrderErrors | TNewOrderErrors = {};
  const {
    fields: {
      deliveryAddress,
      deliveryCost,
      itemDescription,
      itemPrice,
      fulfillmentDate,
      orderMethod,
      costSummary,
      orderDate,
      paymentStatus,
      fulfilledAt,
      orderTakenBy,
      recipientContact,
      recipientFirstName,
      recipientLastName,
      customerFirstName,
    },
  } = state;
  if (typeof itemPrice !== 'number') errors = { ...errors, itemPrice: 'Is required for complete orders' };
  if (orderMethod === EOrderMethod.DELIVERY) {
    if (!recipientContact) {
      errors = { ...errors, recipientContact: 'Is required for complete orders' };
    }
    if (!recipientFirstName) {
      errors = { ...errors, recipientFirstName: 'Is required for complete orders' };
    }
    if (!recipientLastName) {
      errors = { ...errors, recipientLastName: 'Is required for complete orders' };
    }
    // Only harrods can edit the delivery cost manually
    if (typeof deliveryCost !== 'number' && fulfilledAt === EStores.HARRODS) {
      errors = { ...errors, deliveryCost: 'Is required for complete orders' };
    }
  }
  if (typeof costSummary !== 'number') errors = { ...errors, costSummary: 'Is required for complete orders' };
  if (orderMethod === EOrderMethod.DELIVERY && !deliveryAddress)
    errors = { ...errors, deliveryAddress: 'Is required for complete orders' };
  if (!fulfillmentDate) errors = { ...errors, fulfillmentDate: 'Is required for complete orders' };
  if (!orderDate) errors = { ...errors, orderDate: 'Is required for complete orders' };
  if (!orderMethod) errors = { ...errors, orderMethod: 'Is required for complete orders' };
  if (!paymentStatus) errors = { ...errors, paymentStatus: 'Is required for complete orders' };
  if (!fulfilledAt) errors = { ...errors, fulfilledAt: 'Is required for complete orders' };
  if (!orderTakenBy) errors = { ...errors, orderTakenBy: 'Is required for both draft and complete orders' };
  if (!itemDescription?.length) errors = { ...errors, itemDescription: 'Is required for complete orders' };
  if (!customerFirstName)
    errors = {
      ...errors,
      customerFirstName: 'Is required for draft order but optional for complete orders',
    };
  return errors;
};

export const validateDraftOrder = (state: NewOrderInitialStateType | EditOrderInitialStateType) => {
  let errors: TEditOrderErrors | TNewOrderErrors = {};
  const {
    fields: { orderTakenBy, customerFirstName },
  } = state;

  if (!orderTakenBy)
    errors = {
      customerFirstName: errors.customerFirstName,
      orderTakenBy: 'Is required for both draft and complete orders',
    };
  if (!customerFirstName)
    errors = {
      orderTakenBy: errors.orderTakenBy,
      customerFirstName: 'Is required for draft order but optional for complete orders',
    };
  return errors;
};
